import "./SearchResult.scss";
import { VuiLink } from "@ui/components";
import { SearchResultProps } from "@customTypes/search";
import AllTextsWithContents from "@ui/components/allTextsWithContents/AllTextsWithContents";
import { useConfigContext } from "@services/context/ConfigurationContext";
import { getMetadataField } from "@services/getMetadataValue";
import { Badge } from "@ui/components/badge/Badge";

import {
  VuiSpacer,
  VuiText,
  VuiTextColor,
  VuiTitle
} from "@adventera/vectara-ui";

export const SearchResult = ({ doc, res, position }: SearchResultProps) => {

  const {searchLinks, filters, newLineSeparatorTag, runName} = useConfigContext();
  const elementId = `searchResult_${(position + 1)}`
  const {paragraphes, sectionMetadata} = res;

  const getPathToResult = () => {
    
    const metadata = doc.metadata || [];
    let sourceType = getMetadataField(metadata, "source_type")

    // Les docs de contiennent pas cette metadata, ca correspond aux anciens crawls avec génération de PDF et non de HTML pour les sites webs
    // On pourra supprimer ce if et son contenu qd toutes les données seront crawlées avec la nouvelle méthode : génération et upload de html
    // Attention : la méthode est aussi utilisée pour seldon, donc ne pas forcément supprimer au final, reprendre la logique
    // ANCIENNE METHODE
    if(sourceType == "-1"){
      console.log("Ancienne méthode")
      let tabPathToResult = JSON.parse(getMetadataField(sectionMetadata, 'breadcrumb'))
      // Cas d'un document HTML
      if(tabPathToResult !== -1){
        tabPathToResult = tabPathToResult.map((str: string) => str.replace(new RegExp(newLineSeparatorTag, "g"), ""))
        const pathToResult = 
          tabPathToResult.join(" > ")[tabPathToResult.join(" > ").length - 1] === ":" 
            ? tabPathToResult.join(" > ").substring(0, tabPathToResult.join(" > ").length - 1)
            : tabPathToResult.join(" > ");
        return pathToResult
      }
      return doc.id
    }

    // Nouvelle gestion, avec génération d'HTML pour les sites webs, on a ajouté une nouvelle metadata source_type
    // A trme, on doit garder que ca et supprimer le if au dessus quand toutes les données auront été crawlées avec le nouveau crawl
    console.log("Nouvelle méthode")
    if(sourceType == "url" || sourceType == "pdf"){
      return doc.id
    }
    // Cas d'un document HTML (ex : collectiv)
    if(sourceType == "html"){
      let tabPathToResult = JSON.parse(getMetadataField(sectionMetadata, 'breadcrumb'))
      tabPathToResult = tabPathToResult.map((str: string) => str.replace(new RegExp(newLineSeparatorTag, "g"), ""))
      const pathToResult = 
        tabPathToResult.join(" > ")[tabPathToResult.join(" > ").length - 1] === ":" 
          ? tabPathToResult.join(" > ").substring(0, tabPathToResult.join(" > ").length - 1)
          : tabPathToResult.join(" > ");
      return pathToResult
    }
    
  }

  const getUrlAndTitle = () => {
    const metadata = doc.metadata || [];
    const title = getMetadataField(metadata, "title")
    let url: string | undefined;

    if(runName != "seldon_webdette"){

      const foundLink = searchLinks ? searchLinks.find(link => link.id === doc.id) : undefined;
      const anchor : string = getMetadataField(sectionMetadata, "anchor")
      url = foundLink ? `${foundLink.searchTitleUrl}#${anchor}` : undefined
      
      // if(!url && !doc.id.endsWith('pdf')){
      //   url = doc.id
      // }
      if(!url){
        url = doc.id
      }
    }
    else {
      const baseUrl = "/files/"+getMetadataField(res.sectionMetadata, "pdf");

      
      // Différenciation de la gestion des ancres en fonction du navigateur utilisé
      const userAgent = window.navigator.userAgent;
      if(userAgent.includes("Firefox")){
        console.log("Le navigateur est Firefox")
        url = baseUrl+"#page="+getMetadataField(res.sectionMetadata, "anchor_page_num")
      }
      else if(userAgent.includes("Edg")){
        console.log("Le navigateur est Edg")
        url = baseUrl+"#page="+getMetadataField(res.sectionMetadata, "anchor_page_num")
      }
      else if (userAgent.includes("Chrome")) {
        console.log("Le navigateur est Chrome")
        url = baseUrl+"#"+getMetadataField(res.sectionMetadata, "anchor")
      }
    }

    return {url, title}
  }
    
  
  const headerColumns = getMetadataField(sectionMetadata, "header_columns") !== "-1" ? JSON.parse(getMetadataField(sectionMetadata, "header_columns")) : []
  var appHeaderElement =  document.getElementById(`appHeader`);
  var hauteur = appHeaderElement?.offsetHeight!;
  document.documentElement.style.setProperty('--hauteur', hauteur + 'px');

  const {url, title} = getUrlAndTitle()

  return (
    <div className="searchResult" id={elementId}>
      <div className="result_header">
        <div className="searchResult__position">{position + 1}</div>
          <p>
            {
              filters.isEnabled && filters.valueToLabelMap[getMetadataField(doc.metadata, "source")] &&
              <Badge
              text={filters.valueToLabelMap[getMetadataField(doc.metadata, "source")]}
              textType="source"
              color="normal"
              className="vuiBage--unclickable"
            />
            }

            {"  "}
            <VuiTitle size="m">
              {url ? (
                <VuiLink href={url} target="_blank">
                  {title} 
                </VuiLink>
              ) : (
                <div>{title}</div>
              )}
            </VuiTitle>
          </p>

          <VuiSpacer size="xs" />
          <VuiText size="s">
            <p><VuiTextColor color="subdued">{getPathToResult()}</VuiTextColor></p>
          </VuiText>
      </div>
      
      <div className="result_content">
        <VuiSpacer size="s" />
          <AllTextsWithContents
            paragraphes={paragraphes}
            columnNames={headerColumns}
            textSize="s"
          />

        <VuiSpacer size="s" />
      </div>

      {
        runName == "seldon_webdette" &&
        <VuiText size="s">
            <p><VuiTextColor color="subdued">{res.score}</VuiTextColor></p>
        </VuiText>
      }
      
    </div>
  );
};

